<template>
  <div id="app" class="d-flex flex-column">
    <!-- <Header /> -->
    <div class="app__main">
      <router-view />
    </div>
    <div class="container">
      <div class="d-flex justify-content-center py-3 main-footer">
        <p
          class="mb-0 cursor-pointer"
          @click="
            openLink(
              'https://viatim.nl/wp-content/uploads/2023/02/Algemene-voorwaarden-ViaTim-Punten-2023-08_02_2023_.pdf'
            )
          "
        >
          Algemene voorwaarden
        </p>
        <p class="mb-0 mx-1">|</p>
        <p
          class="mb-0 cursor-pointer"
          @click="
            openLink(
              'https://viatim.nl/wp-content/uploads/2021/08/Privacy-statement-ViaTim-NLENG.pdf'
            )
          "
        >
          Privacy
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
  methods: {
    openLink(link) {
      window.open(link, "_blank");
    },
  },
};
</script>

<style lang="scss">
#app {
  min-height: 100vh !important;
}

.invalid-text {
  width: 100%;
  // margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.app__main {
  flex-grow: 1 !important;
}

.form-control::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #bbbabc !important;
}
.form-control:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #bbbabc !important;
}
.form-control::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #bbbabc !important;
}
.form-control:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #bbbabc !important;
}

#RadioButton1 {
  appearance: none;
  padding: 10px;
  background-color: #00e268;
  border-radius: 50%;
  @media (max-width: 991.99px) {
    padding: 8px !important;
  }
}
#RadioButton2 {
  appearance: none;
  padding: 10px;
  background-color: #e3007a;
  border-radius: 50%;
  @media (max-width: 991.99px) {
    padding: 8px !important;
  }
}

.question-label {
  font-size: 16px !important;
  @media (max-width: 767.99px) {
    font-size: 14px !important;
    line-height: 15px !important;
  }
}
label {
  font-size: 20px;
  letter-spacing: 2px;
  line-height: 23px;
  color: #1d1729;
  padding-left: 11px;
  font-weight: 600;
  cursor: pointer !important;

  @media (max-width: 991.99px) {
    font-size: 15px !important;
  }
}

.cursor-pointer:hover {
  cursor: pointer;
}
$theme-colors: (
  "site-primary": #e3007a,
  "site-success": #00e268,
);
@import "../node_modules/bootstrap/scss/bootstrap.scss";

// font-family
* {
  font-family: "Source Sans 3", sans-serif;
}

// font sizes
@for $size from 12 through 96 {
  .fs--#{$size} {
    font-size: $size/16 + rem;
  }
}
html {
  @media (max-width: 991.97px) {
    font-size: 14px;
  }
  @media (max-width: 767.97px) {
    font-size: 12px;
  }
  @media (max-width: 575.97px) {
    font-size: 10px;
  }
}

// font-weight
@for $i from 1 through 9 {
  .fw--#{$i*100} {
    font-weight: $i * 100;
  }
}
.text-pink {
  color: #e3007a;
}

.cursor--pointer {
  cursor: pointer;
}
.question-card {
  border-radius: 30px;
  filter: drop-shadow(0 0 13.5px rgba(0, 0, 0, 0.21));
  background-color: #ffffff;
  .back-arrow {
    // position: absolute;
    width: 25px;
    // transform: rotate(180deg);
    // top: 25px;
    // left: 20px;
    margin-bottom: 8px;
    &:hover {
      cursor: pointer;
    }
  }

  &__progress {
    // font-size: 16px;
    font-size: 14px;
    color: #e3007a;
    font-weight: 600;
  }

  &__question {
    // color: #1d1729;
    // // font-size: 45px;
    // font-size: 35px;
    // // font-weight: 900;
    // font-weight: 800;
    // line-height: 40px;
    color: #1d1729;
    font-size: 30px;
    font-weight: 800;
    line-height: 35px;
  }

  &__right-col {
    border-radius: 0px 30px 30px 0px;

    &-text {
      background-color: #ffe786;
      padding: 5px;
      width: 100% !important;
      top: 35px;
      span {
        // font-size: 16px;
        font-size: 14px;
        color: #1d1729;
        font-weight: 700;
        text-align: center;
      }
    }
  }

  &__left-col {
    padding: 80px 50px !important;
  }

  .form-control {
    background-color: #ffffff !important;
    border: 1px solid #cccbcb !important;
    // font-size: 22px;
    font-size: 16px;
    letter-spacing: 2px;
    // color: #bbbabc;
    color: #495057 !important;
    font-weight: 600;
    border-radius: 0px;
    // text-transform: capitalize !important;
  }

  .custom-control {
    margin-right: 2.4rem;
    position: relative;
    input {
      position: absolute;
      left: -1rem;
      top: 5px;
      width: 17px;
      height: 20px;

      @media (max-width: 991.99px) {
        width: 16px !important;
        top: 1px !important;
      }
    }
  }

  .custom-select {
    background-color: #ffffff !important;
    border: 1px solid #cccbcb !important;
    // font-size: 22px;
    font-size: 16px !important;
    letter-spacing: 2px !important;

    color: #495057;
    font-weight: 600 !important;
    border-radius: 0px !important;
  }

  .custom-select:focus {
    color: #495057 !important;
  }

  .btn {
    &_img {
      width: 20px;
      margin-left: 1rem;
    }
    border-radius: 37px;

    background-color: #00e268;
    padding: 10px;

    font-size: 15px;
    letter-spacing: 2px;
    text-transform: uppercase;
    // color: #ffffff;
    color: black !important;
    font-weight: 900;
    border: none;

    &:hover,
    :active,
    :focus {
      background-color: #00e268 !important;
      color: black !important;
    }
  }
  .btn:active,
  .btn:focus {
    background-color: #00e268 !important;
    color: black !important;
    border: none !important;
  }

  .check-item {
    background-color: #ffffff;
    border: 1px solid #cccbcb;
    padding: 9px 12px;
    width: 100%;
    align-items: start;
    display: flex;
    justify-content: start;
    &:hover {
      cursor: pointer;
    }
  }

  .custom-control-label {
    font-size: 22px;
    padding-left: 11px;
    letter-spacing: 2px;
    line-height: 33px;
    color: #bbbabc;
    font-weight: 600;
    font-family: "Source Sans 3", sans-serif;
    cursor: pointer !important;
  }

  .custom-control-label::before {
    // top: 0.5rem;
    background: #ffcc01;
    border: none !important;
  }

  .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-image: none !important;
  }

  @media (max-width: 999.99px) {
    // &__left-col {
    //   padding: 60px 30px !important;
    // }

    &__question {
      font-size: 25px;
    }

    &__progress {
      font-size: 12px;
    }

    .custom-control-label {
      font-size: 20px;
      letter-spacing: 0.5px;
      line-height: 25px;
    }
    .custom-control-label::before {
      top: 0.25rem;
    }

    &__right-col {
      &-text {
        span {
          font-size: 13px;
        }
      }
    }
  }

  @media (max-width: 767.99px) {
    // &__left-col {
    //   padding: 50px 20px !important;
    // }
    &__left-col {
      padding: 50px 40px !important;
    }

    &__question {
      font-size: 20px !important;
      line-height: 30px;
    }

    &__progress {
      font-size: 12px;
    }

    .form-control {
      font-size: 14px;
    }

    .btn {
      font-size: 13px;
      letter-spacing: 1px;
    }

    .custom-control-label {
      font-size: 19px;
    }
    &__right-col {
      &-text {
        span {
          font-size: 12px;
        }
      }
    }
  }

  @media (max-width: 575.99px) {
    // &__left-col {
    //   padding: 40px 15px !important;
    // }
    &__left-col {
      padding: 40px 35px !important;
    }

    &__question {
      font-size: 18px !important;
      line-height: 25px;
    }

    &__progress {
      font-size: 11px;
    }

    .form-control {
      font-size: 10px;
      letter-spacing: 1px;
    }

    .btn {
      font-size: 12px;
      letter-spacing: 1px;
    }

    .custom-control-label {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.3px;
    }
    &__right-col {
      &-text {
        span {
          font-size: 10px;
        }
      }
    }
  }
}
</style>
