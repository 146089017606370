<template>
  <div class="main_row">
    <b-row no-gutters>
      <b-col cols="6" class="left_col d-flex align-items-center pl-md-0 pl-3">
        <div
          class="w-100 d-flex justify-content-md-center justify-content-start"
        >
          <div class="container-width">
            <div
              class="justify-content-start align-items-start d-flex flex-column logo_div"
            >
              <img
                src="../assets/logo.png"
                alt=""
                class="logo cursor-pointer"
                @click="goToPreviousPage"
              />
              <h6
                class="mt-1 mb-0 img-btm-text cursor-pointer"
                @click="goToPreviousPage"
              >
                Al meer dan 750+ <br class="d-md-none d-block" />
                actieve pakketpunten
              </h6>
            </div>
          </div>
        </div>
      </b-col>
      <b-col cols="6" class="right_col d-flex align-items-center">
        <div class="w-100 d-flex justify-content-md-center justify-content-end">
          <div class="container-width">
            <div
              class="justify-content-center align-items-end d-flex flex-column logo_div h-100"
            >
              <h5 class="heading-right">Super gemakkelijk:</h5>
              <h5 class="heading-right">
                Neem pakketjes aan voor <br class="d-md-none d-block" />
                je buren en verdien bij!
              </h5>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>

  <!-- <b-container>
    <b-row
      class="pt-4 pb-1 d-flex justify-content-center align-items-center main_row"
    >
      <b-col
        class="justify-content-start align-items-start d-flex flex-column logo_div"
      >
        <img
          src="../assets/logo.png"
          alt=""
          class="logo cursor-pointer"
          @click="goToPreviousPage"
        />
        <h6 class="mt-1 img-btm-text cursor-pointer" @click="goToPreviousPage">
          Al meer dan 750+ actieve pakketpunten
        </h6>
      </b-col>
      <b-col
        class="justify-content-end align-items-end d-flex flex-column logo_div"
      >
        <h5 class="heading-right">Super gemakkelijk:</h5>
        <h5 class="heading-right">
          Neem pakketjes aan voor je buren en verdien bij!
        </h5>
      </b-col>
    </b-row>
  </b-container> -->
</template>

<script>
export default {
  name: "Header",
  methods: {
    goToPreviousPage() {
      this.$emit("pageClick");
    },
  },
};
</script>
<style lang="scss" scoped>
.main_row {
  width: 100% !important;
  overflow: hidden !important;
  /* @media (max-width: 575px) {
    display: flex;
    flex-direction: column;
  } */
}

.container-width {
}

.right_col {
  background-color: #efefef !important;
  min-height: 105px;
}

.left_col {
  min-height: 105px;
}

@media (max-width: 767.99px) {
  .right_col {
    min-height: 85px;
    padding-right: 3rem !important;
  }
  .left_col {
    /* min-height: 60px; */
    min-height: 85px;
    padding-left: 3rem !important;
  }
}
.logo_div {
  /* @media (max-width: 575px) {
    align-items: center !important;
    justify-content: center !important;
    margin-bottom: 3px;
  } */
}

.logo {
  @media (max-width: 992px) {
    width: 50%;
  }
  @media (max-width: 767.99px) {
    /* width: 35%; */
    width: 50% !important;
  }
}
.img-btm-text {
  font-size: 16px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 400;
  font-family: "Source Sans 3", sans-serif;
  @media (max-width: 992px) {
    font-size: 12px;
  }
  @media (max-width: 575.99px) {
    font-size: 11px;
  }
  /* @media (max-width: 768px) {
    font-size: 10px;
  } */
}
.heading-right {
  font-size: 18px;
  color: #e2007a;
  font-weight: 800;
  font-family: "Source Sans 3", sans-serif;
  line-height: 1;
  margin-bottom: 0;
  @media (max-width: 992px) {
    font-size: 14px;
  }
  @media (max-width: 767.99px) {
    font-size: 12px;
    text-align: end !important;
  }
  @media (max-width: 575.99px) {
    font-size: 11px;
  }
}
</style>
