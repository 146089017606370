import axios from "axios";
import authHeader from "./AuthHeader";

const API_URL = "https://leadgen.republish.nl/api";

class LeadService {
  createLead(payload) {
    console.log("create lead");
    return axios.post(API_URL + "/sponsors/2072/leads", payload, {
      headers: authHeader(),
    });
  }
}

export default new LeadService();
