import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "../views/Home.vue";
// import ThankYou from "../views/ThankYou.vue";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   name: "Home",
  //   component: Home,
  // },
  // {
  //   path: "/bedankt",
  //   name: "Bedankt",
  //   component: ThankYou,
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
